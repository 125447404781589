<template>
  <div class="center">

    <!-- 头部 -->
    <div
      class="content"
      
    >

      <!-- 全部小游戏列表 -->
      <div class="reward">
        <!-- <div class="arena-top">
          <img
            src="https://ppyos.xijiuyou.com/imgs/smartgame/rank-star.png"
            alt=""
          />
          <p><span>全部小游戏</span>总有一款你爱的</p>
        </div> -->
        <van-list v-model="loading" :finished="finished" @load="whole">
          <div class="arena—center">
            <van-cell
              v-for="(item, index) in wholelist"
              :key="index"
              @click="gowhole(item)"
            >
              <img :src="item.icon" alt="" />
              <p>{{ item.sgname }}</p>
            </van-cell>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import game from "../../api/game";
import { Toast } from "vant";
export default {
  data() {
    return {
      wholelist: [],
      loading: false,
      finished: false,
      page: 1,
    };
  },
  methods: {
    gowhole(item) {
      //全部小游戏跳转
      try {
        console.log(item)
        if (item.sourceType == 0) {
          if (item.topRightCode == 1) {
            window.android.createNewWeb(
              window.location.href.split("game")[0] + "arena?sgid=" + item.sgid
            );
          } else if (item.topRightCode == 2) {
            window.android.createNewWeb(
              window.location.href.split("game")[0] + "reward?sgid=" + item.sgid
            );
          } else {
            this.gogame(item.sgid);
          }
        } else {
          this.gogame(item.sgid);
        }
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    // 全部小游戏
    whole() {
      game
        .gameList({
          page: this.page,
          pageSize: 8,
        })
        .then((res) => {
          this.wholelist.push(...res.list);
          // 加载状态结束
          this.loading = false;

          // 数据全部加载完成
          if (res.count < 8) {
            this.finished = true;
          } else {
            this.page++;
          }
        });
    },
    // 开始小游戏
    gogame(sgid) {
      game.smartstart({ gid: sgid }).then((res) => {
        try {
          window.android.openH5Game(res.data.link);
        } catch (err) {
          console.log(err);
        }
      });
    }
  },
};
</script>
<style scoped>
.content {
  background: #ffffff;
  margin: 0 auto;
  border-radius: 10px;
}
.center {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background: #ffffff;
}
.my-swipe{
  width: calc(100% - 24px)!important;
  margin: 0 auto!important;
}
.van-swipe-item img {
  height: 88px;
  width: 100%;
}
.arena {
  width: 100%;
  padding: 12px 12px 0 12px;
  background: #ffffff;
  margin: 10px auto 0;
}
.arena-top {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.arena-top span {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 4px;
}
.arena-top img {
  width: 18px;
  margin-right: 4px;
}
.arena—center {
  display: flex;
  margin-top: 19px;
  flex-wrap: wrap;
}
.arena—center img {
  width: 71px;
  height: 71px;
  border-radius: 10px;
}
.arena—center div {
  margin-bottom: 10px;
}
.arena—center div p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Medi, PingFang SC;
  font-weight: 400;
  color: #333333;
  white-space: nowrap;
  text-align: center;
}
.arena—center div p:nth-child(3) {
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  color: #ff5675;
  text-align: center;
}
.van-cell {
  width: 21%;
  padding: 0;
  margin-right: 4%;
}
.arena—center div div p:nth-child(3) span {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff5675;
}
.reward {
  width: 100%;
  padding: 12px;
  background: #ffffff;
  margin: 0px auto 0;
}
.reward-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.reward-center img {
  width: 68px;
  height: 68px;
  margin-right: 10px;
  border-radius: 10px;
}
.reward-center div {
  display: flex;
  align-items: center;
  margin-top: 12px;
  width: 50%;
}
.reward-center div div {
  display: block;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.reward-center div div p:nth-child(2) {
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  color: #ff5675;
}
.van-swipe {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 10px;
}
.arena—center-pk {
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-y: auto;
}
.arena—center-pk div {
  margin-right: 10px;
}
.todraw {
  width: calc(100% - 70px);
  margin-top: 50px;
}
</style>
<style>
.van-swipe-item {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.van-cell::after {
  border-bottom: 0;
}
.drwa-ball {
  position: fixed;
  right: 12px;
  bottom: 50px;
  width: 74px;
}
.title-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 12px;
}
.title-time p:nth-child(1) {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.title-time p:nth-child(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  display: flex;
  align-items: center;
}
.title-time p:nth-child(2) span {
  width: 20px;
  height: 20px;
  background: #444444;
  border-radius: 4px;
  font-size: 14px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ffffff;
  text-align: center;
  padding-top: 2px;
  box-sizing: border-box;
  margin: 0 4px;
}
</style>
<style>
.van-cell__value{
  overflow:visible;
}
.van-cell{
  overflow:visible;
}
</style>
